import AddressAutocomplete from 'components/shared/AddressAutocomplete';
import StateSelect from 'components/StateSelect';
import { FormField } from './form';

export const ADDRESS_FORM_FIELDS: FormField[] = [
  {
    name: 'address',
    label: 'Street address',
    Component: AddressAutocomplete,
  },
  {
    name: 'address2',
    label: 'Apt/Unit/Lot/Ste (if applicable)',
  },
  {
    name: 'city',
    label: 'City',
  },
  {
    name: 'state',
    label: 'State',
    Component: StateSelect,
  },
  {
    name: 'zipcode',
    label: 'Zip code',
  },
];
