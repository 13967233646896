import { Box, Link as MuiLink } from '@mui/material';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { Link } from 'react-router-dom';

import { theme } from 'context/ThemeProvider';
import { ACCOUNT_EXISTS_EMAIL_KEY } from 'helpers/constants';

const LogOutButton: React.FC = () => {
  const { track } = useProductAnalytics();

  return (
    <Box textAlign="center" marginTop={theme.spacing(1)}>
      <MuiLink
        component={Link}
        to="/logout"
        underline="always"
        variant="footnote"
        onClick={() => {
          track({
            namespace: 'ui',
            event: 'logout.clicked',
          });
          sessionStorage.removeItem(ACCOUNT_EXISTS_EMAIL_KEY);
        }}
      >
        Log out
      </MuiLink>
    </Box>
  );
};

export default LogOutButton;
