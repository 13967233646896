import { ErrorBoundary, Provider } from '@rollbar/react';
import type { PostHog } from 'posthog-js';
import ReactDOM from 'react-dom';
import ReactPixel from 'react-facebook-pixel';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import config from './config';
import { rollbar, rollbarEnabled } from './helpers/rollbar';

import App from 'components/App';
import { configureAmplify } from 'helpers/amplify';
import { KovoError } from 'libs/KovoError';
import { KovoAppCustomInfo } from 'types/mobile-app';

const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init(config.FACEBOOK_PIXEL_ID, undefined, options);

configureAmplify();

type TikTokEvent =
  | 'ApplicationSubmitted'
  | 'AddPaymentInfo'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'ClickButton'
  | 'CompletePayment'
  | 'CompleteRegistration'
  | 'Contact'
  | 'Download'
  | 'InitiateCheckout'
  | 'PlaceAnOrder'
  | 'Search'
  | 'SubmitForm'
  | 'Subscribe'
  | 'ViewContent';

// https://ads.tiktok.com/help/article/about-parameters
interface TikTokParams {
  content_type?: 'product' | 'product_group';
  contents?: {
    content_id?: string;
    price?: number;
    quantity?: number;
    content_name?: string;
    content_category?: string;
  }[];
  currency?: string;
  value?: number;
  query?: string;
  description?: string;
  status?: string;
}

// https://ads.tiktok.com/marketing_api/docs?id=1739584864945154
interface TikTokEventParams {
  event_id: string;
}

export type ReactNativeWebView = {
  postMessage(msg: string): void;
};

declare global {
  interface Window {
    ttq: {
      track: (
        event: TikTokEvent,
        parameters?: TikTokParams,
        eventParams?: TikTokEventParams,
      ) => void;
      identify: ({
        email,
        phone_number,
        external_id,
      }: {
        email: string;
        phone_number: string;
        external_id: string;
      }) => void;
    };

    ReactNativeWebView?: ReactNativeWebView;
    KovoAppCustomInfo?: KovoAppCustomInfo;
    posthog?: PostHog;
    __CYPRESS_FEATURE_FLAGS?: Record<string, boolean | string>;
    gtm_container_version?: string;
  }
}

const enableMocking = async () => {
  if (import.meta.env.VITE_STAGE !== 'local') {
    return;
  }

  const { worker } = await import('./mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({
    onUnhandledRequest: 'bypass', // Comment this to show logs for unhandled requests
  });
};

(async () => {
  await enableMocking();
  /**
   * Rollbar is disabled in local environments
   */
  if (!rollbarEnabled) {
    ReactDOM.render(<App />, document.getElementById('main'));
    return;
  }

  ReactDOM.render(
    <Provider instance={rollbar}>
      <ErrorBoundary
        /**
         * For KovoErrors caught by the ErrorBoundary, include details
         */
        extra={(error) => {
          return error instanceof KovoError ? error.details : {};
        }}
      >
        <App />
      </ErrorBoundary>
    </Provider>,
    document.getElementById('main'),
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
