import config from 'config';
/**
 * Validates a given Social Security Number (SSN).
 *
 * This function checks the format and validity of an SSN by ensuring it has the correct length,
 * removing hyphens, and validating the individual parts (area number, group number, and serial number).
 *
 * @param value - SSN to validate
 * @returns true if valid, false otherwise
 */
export const validateSSN = (value?: string) => {
  if (!value) return false;

  // Remove nonnumeric characters (including hyphens)
  const cleanSSN = value.replace(/[^0-9]/g, '');
  if (cleanSSN.length !== 9) return false;

  // Extract parts of SSN
  const areaNumber = parseInt(cleanSSN.slice(0, 3));
  const groupNumber = parseInt(cleanSSN.slice(3, 5));
  const serialNumber = parseInt(cleanSSN.slice(5));

  // Validate area number (001-665 or 667-899)
  const isValidArea =
    (areaNumber >= 1 && areaNumber <= 665) ||
    (areaNumber >= 667 && areaNumber <= 899);

  // Validate group number (01-99)
  const isValidGroup = groupNumber >= 1 && groupNumber <= 99;

  // Validate serial number (0001-9999)
  const isValidSerial = serialNumber >= 1 && serialNumber <= 9999;

  // SSN cannot be 123456789 or 234567890
  if (areaNumber === 123 && groupNumber === 45 && serialNumber === 6789)
    return false;
  if (areaNumber === 234 && groupNumber === 56 && serialNumber === 7890)
    return false;

  // SSN cannot be all the same number (e.g. 111-11-1111, 444-44-4444)
  if (
    cleanSSN === '000000000' ||
    cleanSSN === '111111111' ||
    cleanSSN === '222222222' ||
    cleanSSN === '333333333' ||
    cleanSSN === '444444444' ||
    cleanSSN === '555555555' ||
    cleanSSN === '666666666' ||
    cleanSSN === '777777777' ||
    cleanSSN === '888888888' ||
    cleanSSN === '999999999'
  )
    return false;

  return isValidArea && isValidGroup && isValidSerial;
};

/**
 * Validates a given Individual Taxpayer Identification Number (ITIN).
 *
 * This function checks the format and validity of an ITIN by ensuring it has the correct length,
 * removing hyphens, and validating the individual parts (area number, group number, and serial number).
 *
 * @param value - ITIN to validate
 * @returns true if valid, false otherwise
 */
export const validateITIN = (value?: string) => {
  if (!value) return false;

  // Remove nonnumeric characters (including hyphens)
  const cleanITIN = value.replace(/[^0-9]/g, '');
  if (cleanITIN.length !== 9) return false;

  // Validate ITIN format
  const areaNumber = parseInt(cleanITIN.slice(0, 3));
  const groupNumber = parseInt(cleanITIN.slice(3, 5));
  const serialNumber = parseInt(cleanITIN.slice(5));

  // Validate area number (900-999)
  const isValidArea = areaNumber >= 900 && areaNumber <= 999;

  // Validate group number (50-65, 70-88, 90-92, 94-99)
  const isValidGroup =
    (groupNumber >= 50 && groupNumber <= 65) ||
    (groupNumber >= 70 && groupNumber <= 88) ||
    (groupNumber >= 90 && groupNumber <= 92) ||
    (groupNumber >= 94 && groupNumber <= 99);

  // Validate serial number (0000-9999)
  const isValidSerial = serialNumber >= 0 && serialNumber <= 9999;

  return isValidArea && isValidGroup && isValidSerial;
};

/**
 * Validates a given Social Security Number (SSN) or Individual Taxpayer Identification Number (ITIN).
 *
 * This function checks the format and validity of an SSN or ITIN by ensuring it has the correct length,
 * removing hyphens, and validating the individual parts (area number, group number, and serial number).
 *
 * Please visit page 266 here https://www.irs.gov/pub/irs-pdf/p4164.pdf for more details.
 *
 * @param value - SSN or ITIN to validate
 * @returns true if valid SSN OR ITIN, false otherwise
 */
export const validateSSNorITIN = (value?: string) => {
  // Sentilink staging uses SSNs like 111-11-1200 and 123-45-6789, so we should bypass this check locally
  const isLocal = config.VITE_STAGE === 'local' || config.VITE_STAGE === 'dev';
  return isLocal || validateSSN(value) || validateITIN(value);
};
