import { CognitoUser } from '@aws-amplify/auth';
import { LoadingButton } from '@mui/lab';
import { Alert, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import TextField from 'components/shared/TextField';
import { LocationState } from 'components/SignUpConfirmForm';
import { useAuthContext, UserAuthDetails } from 'context/AuthProvider';
import { theme } from 'context/ThemeProvider';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import { normalizeEmail } from 'helpers/utils';
import { KovoUserNotConfirmedError } from 'libs/KovoError';
import { getCognitoUserAuthDetails, signInUser } from 'libs/signupHelpers';
import { ROUTES } from 'components/Routes';
import { ACCOUNT_EXISTS_EMAIL_KEY } from 'helpers/constants';
import { useClientsService } from 'hooks/useClientsService';
import {
  fetchCurrentUser,
  USE_CURRENT_USER_QUERY_KEY,
} from 'hooks/queries/useCurrentUser';

interface FormValues {
  email: string;
  password: string;
}

export const COGNITO_AUTHENTICATION_ERRORS = [
  'UserNotFoundException',
  'NotAuthorizedException',
  'UsernameExistsException',
];

export const getValidRedirectPath = (path: string): string => {
  try {
    const url = new URL(path, window.location.origin);
    return `${url.pathname}${url.search}${url.hash}`;
  } catch {
    // If the path is not valid (URL is not in the same origin), return the home path
    return ROUTES.APP;
  }
};

const LogInForm: React.FC = () => {
  const history = useHistory();
  const { track } = useProductAnalytics();
  const queryClient = useQueryClient();
  const clientsService = useClientsService();

  const savedEmailParam = sessionStorage.getItem(ACCOUNT_EXISTS_EMAIL_KEY);

  if (savedEmailParam) {
    sessionStorage.removeItem(ACCOUNT_EXISTS_EMAIL_KEY);
    track({
      namespace: 'ui',
      event: 'login.email.prefilled',
    });
  }

  const { state: routerState } = useLocation<
    LocationState & {
      loginEmail?: string;
      emailConfirmed?: boolean;
    }
  >();

  const { authChangeCallback } = useAuthContext();

  const onSubmit = (values: FormValues) => {
    loginUser(values);
  };

  const { touched, errors, values, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        email: savedEmailParam || routerState?.loginEmail || '',
        password: '',
      },
      validationSchema: yup.object().shape({
        email: yup.string().required('Email is required'),
        password: yup.string().required('Password is required'),
      }),
      onSubmit,
      validateOnBlur: false,
    });

  /**
   * Initially, we were not lowercasing the email before sending it to Cognito.
   * This resulted in a bunch of customer complaints that they could not log in with their
   * email that they specified because they had the letter casing wrong (I insteaed of i, a instead of A).
   * This login flow attempts to use the provided input, but also tries the email lowercased.
   * New signups have the email lowercased, but we cannot assume that the old users are migrated to have
   * lowercase email usernames.
   */
  const logInUser = async ({ email, password }: FormValues) => {
    let normalizedEmail = normalizeEmail(email);

    try {
      const signInResult = await signInUser(normalizedEmail, password);

      const cognitoUser = signInResult.cognitoUser as
        | (CognitoUser & {
            pool: {
              clientId: string;
            };
          })
        | undefined;

      const authDetails = await getCognitoUserAuthDetails(cognitoUser);

      if (cognitoUser) {
        const cognitoUserPoolClientId = cognitoUser?.pool?.clientId;
        const cognitoUserPoolUserId = authDetails.username;

        clientsService.setHeaderOptions({
          userId: cognitoUserPoolClientId,
          userPoolUserId: cognitoUserPoolUserId,
        });

        const currentUser = await fetchCurrentUser(clientsService);
        queryClient.setQueryData(USE_CURRENT_USER_QUERY_KEY, currentUser);
      }

      return {
        user: cognitoUser,
        userDetails: authDetails,
        email: normalizedEmail,
        password,
      };
    } catch (error) {
      if (error instanceof KovoUserNotConfirmedError) {
        return {
          email: normalizedEmail,
          password,
        };
      }

      throw error;
    }
  };

  const onSuccess = ({
    user,
    userDetails,
    email,
    password,
  }: {
    user?: CognitoUser;
    userDetails?: UserAuthDetails;
    email: string;
    password: string;
  }) => {
    if (!user && !userDetails) {
      authChangeCallback(null, null, { email, password }, true);
      history.replace('/signup-confirm', {
        redirectPath: routerState?.redirectPath,
      });
      return;
    }

    if (user && userDetails) {
      const loggedInAt = new Date().toISOString();
      track({
        namespace: 'user',
        event: 'login.submission.succeeded',
        properties: {
          loggedInAt,
        },
      });
      track({
        customEventName: 'login',
        properties: {
          loggedInAt,
        },
        sendAsConversionEventToMobileApp: true,
      });
      authChangeCallback(user, userDetails, undefined, true);

      const redirectPath = routerState?.redirectPath
        ? getValidRedirectPath(routerState.redirectPath)
        : ROUTES.APP;

      history.replace(redirectPath);

      return;
    }

    if (rollbarEnabled) {
      rollbar.error('Unknown login state', {
        email,
      });
    }

    throw new Error('Unknown login state');
  };

  const {
    mutate: loginUser,
    error: serverError,
    isLoading,
  } = useMutation<
    {
      user?: CognitoUser;
      userDetails?: UserAuthDetails;
      email: string;
      password: string;
    },
    Error,
    FormValues
  >(logInUser, { onSuccess });

  return (
    <>
      {serverError && (
        <Alert
          severity="error"
          icon={false}
          sx={{ marginBottom: theme.spacing(5) }}
        >
          {serverError?.message}
        </Alert>
      )}

      {routerState?.emailConfirmed && (
        <Alert
          severity="success"
          icon={false}
          sx={{ marginBottom: theme.spacing(5) }}
        >
          Email verified. Please enter your password to log in.
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} marginBottom={theme.spacing(3)}>
          <Grid item xs={12}>
            <TextField
              name="email"
              type="email"
              label="Email"
              autoComplete="email"
              error={!!(touched.email && errors.email)}
              errorText={errors.email}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="password"
              type="password"
              label="Password"
              autoComplete="current-password"
              error={!!(touched.password && errors.password)}
              errorText={errors.password}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
        </Grid>

        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          fullWidth
        >
          Log In
        </LoadingButton>
      </form>
    </>
  );
};

export default LogInForm;
