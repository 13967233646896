import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import KovoDialog from 'components/KovoDialog';
import { theme } from 'context/ThemeProvider';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { useEffect } from 'react';
import { FormField } from 'helpers/form';
import BirthdateInput, {
  BIRTHDATE_INPUT_LABEL,
} from 'components/shared/BirthdateInput';
import {
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { FormValues } from '../ApplyForm';

interface DialogProps extends ContentProps {
  open: boolean;
  onClose: () => void;
}

interface ContentProps {
  onConfirm: () => void;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  touched: Partial<Record<keyof FormValues, boolean>>;
  errors: Partial<Record<keyof FormValues, string>>;
  values: FormValues;
}

export const ConfirmDobDialogContent: React.FC<ContentProps> = ({
  onConfirm,
  setFieldValue,
  handleChange,
  handleBlur,
  touched,
  errors,
  values,
}) => {
  const { track } = useProductAnalytics();

  useEffect(() => {
    track({
      namespace: 'ui',
      event: 'apply.dob-confirmation.shown',
    });
  }, []);

  const FORM_FIELDS: FormField[] = [
    {
      name: 'birthday',
      label: BIRTHDATE_INPUT_LABEL,
      Component: BirthdateInput,
    },
  ];

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <DialogTitle>
        <Typography variant="h4" marginBottom={theme.spacing(2)}>
          Confirm your information
        </Typography>
        <Typography>
          Please re-enter your birthday in order to verify your identity.
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{ marginTop: theme.spacing(-1), marginBottom: theme.spacing(-2) }}
      >
        <Grid
          container
          spacing={{ xs: 2, sm: 3 }}
          marginBottom={theme.spacing(3)}
        >
          {FORM_FIELDS.map(
            ({ name, label, Component = TextField, helperText }, index) => {
              const props = {
                name,
                label,
                value: values[name as keyof FormValues],
                error: !!(
                  touched[name as keyof FormValues] &&
                  errors[name as keyof FormValues]
                ),
                errorText: errors[name as keyof FormValues],
                helperText,
                setFieldValue,
                onChange: handleChange,
                onBlur: handleBlur,
                fullWidth: true,
                ...(index === 0 && {
                  autoFocus: true,
                }),
              };

              return (
                <Grid item xs={12} key={name}>
                  <Component {...props} />
                </Grid>
              );
            },
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ paddingX: theme.spacing(0) }}>
        <Button variant="contained" onClick={onConfirm} fullWidth>
          Confirm
        </Button>
      </DialogActions>
    </Box>
  );
};

const ConfirmDobDialog: React.FC<DialogProps> = ({
  open,
  onConfirm,
  onClose,
  setFieldValue,
  handleChange,
  handleBlur,
  touched,
  errors,
  values,
}) => {
  return (
    <KovoDialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      closeIcon={<ArrowBackIcon />}
      sx={{ margin: theme.spacing(-2) }}
    >
      <ConfirmDobDialogContent
        onConfirm={onConfirm}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
        values={values}
      />
    </KovoDialog>
  );
};

export default ConfirmDobDialog;
