import config from 'config';

// Feature flags
export const FLAG_IDV_V2 = 'IDV_V2';
export const FLAG_USE_SSN_SERVICE = 'USE_SSN_SERVICE';

const FEATURE_FLAG_LOCAL_OVERRIDES: Record<string, string | boolean> = {
  // Add feature flags here for local development
  // [FLAG_IDV_V2]: true,
  // [FLAG_USE_SSN_SERVICE]: false,
};

const getCypressFeatureFlagValue = <T extends string | boolean>(
  featureFlag: string,
): T | undefined => {
  const cypressFlags = window.__CYPRESS_FEATURE_FLAGS;
  if (cypressFlags && featureFlag in cypressFlags) {
    console.log(
      '🧪 Cypress feature flag override:',
      featureFlag,
      cypressFlags[featureFlag],
    );
    return cypressFlags[featureFlag] as T;
  }
  return undefined;
};

const getLocalFeatureFlagValue = <T extends string | boolean>(
  featureFlag: string,
): T | undefined => {
  if (config.VITE_STAGE === 'local') {
    console.groupCollapsed(
      '[⚛︎] Requested feature flag in local environment:',
      featureFlag,
    );
    if (FEATURE_FLAG_LOCAL_OVERRIDES[featureFlag] !== undefined) {
      console.log(
        `Overriding feature flag ${featureFlag} with ${FEATURE_FLAG_LOCAL_OVERRIDES[featureFlag]}.`,
      );
      console.groupEnd();
      return FEATURE_FLAG_LOCAL_OVERRIDES[featureFlag] as T;
    }
    console.log('No local override found.');
    console.groupEnd();
  }
  return undefined;
};

export const getFeatureFlagValue = <T extends string | boolean>(
  featureFlag: string,
): T | undefined => {
  // Cypress can override the feature flag value, let's check for that first
  const cypressValue = getCypressFeatureFlagValue<T>(featureFlag);
  if (cypressValue !== undefined) {
    return cypressValue;
  }

  const localValue = getLocalFeatureFlagValue<T>(featureFlag);
  if (localValue !== undefined) {
    return localValue;
  }

  const value = window.posthog?.getFeatureFlag(featureFlag);
  if (value === undefined) {
    return undefined;
  }
  return value as T;
};

export const isFeatureFlagEnabled = (featureFlag: string): boolean => {
  // Cypress can override the feature flag value, let's check for that first
  const cypressValue = getCypressFeatureFlagValue<boolean>(featureFlag);
  if (cypressValue !== undefined) {
    return cypressValue;
  }

  const localValue = getLocalFeatureFlagValue<boolean>(featureFlag);
  if (localValue !== undefined) {
    return localValue;
  }

  const isEnabled = window.posthog?.isFeatureEnabled(featureFlag);
  return isEnabled === true;
};
