import { Auth } from 'aws-amplify';
import { thoroughlyRemoveCookie } from 'helpers/cookies';
import { sendMessageToMobileApp } from 'helpers/mobile-app';
import { useMutation, useQueryClient } from 'react-query';
import { useClientsService } from './useClientsService';

function useLogOut() {
  const queryClient = useQueryClient();
  const clientsService = useClientsService();

  const logOut = async () => {
    await Auth.signOut();

    try {
      // Double check that the cookies are cleared
      // https://linear.app/kovo/issue/KOV-402/400-error-when-accessing-a-kovo-website
      await Auth.Credentials.clear();

      // Get all cookies
      const cookies = document.cookie
        .split(';')
        .map((cookie) => cookie.trim().split('=')[0]);

      // Clear cognito cookies with explicit domains
      cookies.forEach((cookieName) => {
        if (cookieName.startsWith('CognitoIdentityServiceProvider')) {
          thoroughlyRemoveCookie(cookieName);
        }
      });
    } catch (err) {}

    clientsService.setHeaderOptions({
      userId: '',
      userPoolUserId: '',
    });
  };

  const onSuccess = () => {
    queryClient.clear();

    // send message to mobile app letting it know that the user has logged out
    if (window.ReactNativeWebView) {
      sendMessageToMobileApp({
        eventType: 'kovo.webapp.log_out',
      });
    }
  };

  return useMutation<void, Error, void>(logOut, { onSuccess });
}

export default useLogOut;
