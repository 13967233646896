import {
  Box,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox as MuiCheckbox,
  SxProps,
  Typography,
  TypographyProps,
} from '@mui/material';

import './Checkbox.css';

import { KOVO_BLUE, LIGHT_GREY, theme } from 'context/ThemeProvider';

import Checkmark from 'assets/img/icons/checkmark-white.svg';

interface Props {
  label: string | React.ReactNode;
  /**
   * Props for the label text.
   * Will only be applied if label is a string, otherwise the label will be
   * rendered as a React node (where you can use custom styles).
   * @type {TypographyProps}
   * @memberof Props
   */
  labelProps?: TypographyProps;
  name: string;
  checked: boolean;
  showBorder?: boolean;
  error?: boolean;
  errorText?: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  sx?: SxProps;
  color?: CheckboxProps['color'];
  checkboxSize?: number;
}

const DEFAULT_CHECKBOX_SIZE = 20;

const Checkbox: React.FC<Props> = ({
  label,
  labelProps,
  name,
  checked,
  showBorder,
  error,
  errorText,
  onChange,
  sx,
  color = KOVO_BLUE,
  checkboxSize = DEFAULT_CHECKBOX_SIZE,
}) => {
  const checkboxSx = {
    width: `${checkboxSize}px`,
    height: `${checkboxSize}px`,
    border: '1.5px solid',
    borderRadius: '2px',
  };

  const labelElement =
    typeof label === 'string' ? (
      <Typography variant="footnote" {...labelProps}>
        {label}
      </Typography>
    ) : (
      label
    );

  return (
    <FormControl error={error} sx={{ position: 'relative', ...sx }}>
      <FormControlLabel
        label={labelElement}
        control={
          <MuiCheckbox
            name={name}
            checked={checked}
            onChange={onChange}
            sx={{
              '&.PrivateSwitchBase-root': {
                padding: 0,
                marginRight:
                  typeof label === 'string' ? theme.spacing(1.75) : 0,
              },

              ...(error && { color: theme.palette.error.main }),
            }}
            icon={
              <Box
                sx={{
                  ...checkboxSx,
                  borderColor: LIGHT_GREY,
                }}
              />
            }
            checkedIcon={
              <Box
                sx={{
                  ...checkboxSx,
                  borderColor: color,
                  backgroundColor: color,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box component="img" src={Checkmark} alt="" />
              </Box>
            }
          />
        }
        sx={{
          ...(showBorder && {
            border: `1px solid ${LIGHT_GREY}`,
            padding: theme.spacing(1.75),
            borderRadius: theme.spacing(1),
            margin: 0,
            backgroundColor: theme.palette.common.white,
          }),
          marginRight: typeof label !== 'string' ? 0 : undefined,
        }}
      />

      {error && errorText && (
        <FormHelperText
          sx={{
            position: 'absolute',
            top: '100%',
          }}
        >
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Checkbox;
