import Cookies from 'js-cookie';

import config from 'config';

export const KOVO_REFERRAL_CODE_KEY = 'kovo_referral_code';

export type KovoCookieKey =
  | 'kovo_referral_code'
  | 'kovo_marketing_attributions'
  | '_fbc'
  | '_fbp';

export const getCookie = (key: KovoCookieKey): string | undefined => {
  return Cookies.get(key);
};

export const removeCookie = (key: KovoCookieKey): void => {
  const domain =
    config.VITE_STAGE === 'prod' ? 'kovocredit.com' : window.location.hostname;

  Cookies.remove(key, { path: '/', domain });
};

/**
 * Utility to thoroughly remove a cookie, attempting multiple domain variations
 * @param cookieName Name of the cookie to remove
 */
export const thoroughlyRemoveCookie = (cookieName: string): void => {
  const hostname = window.location.hostname;
  const baseDomain = hostname.startsWith('www.')
    ? hostname.substring(4)
    : hostname;

  // Basic removal
  Cookies.remove(cookieName, { path: '/' });

  // With explicit domains
  if (hostname.startsWith('www.')) {
    Cookies.remove(cookieName, { domain: `.${hostname}`, path: '/' });
    Cookies.remove(cookieName, { domain: hostname, path: '/' });
  }

  // Try with base domain and dot prefix
  Cookies.remove(cookieName, { domain: baseDomain, path: '/' });
  Cookies.remove(cookieName, { domain: `.${baseDomain}`, path: '/' });
};
