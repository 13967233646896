import * as impact from 'helpers/impact';
import { logOnboardingEvent } from 'libs/logger';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { INSTALLMENTS_PRODUCT_ID, LoanProductId } from 'types/schemas';

export const useTrackConversion = () => {
  const { track } = useProductAnalytics();

  return async (
    userId: string,
    username: string,
    email: string,
    productId: LoanProductId,
  ) => {
    if (productId === INSTALLMENTS_PRODUCT_ID) {
      const eventName = 'purchase';

      /**
       * DO NOT CHANGE THIS EVENT ID
       * This event ID is used for tracking purchase events. For now, this is hard coded
       * as the schema is important for services when processing Google Ads conversion retractions.
       * If this changes, Google Ads conversion retractions will not be processed correctly.
       */
      const eventId = `${userId}-${eventName}-installments10`;

      track({
        namespace: 'purchase',
        event: 'submission.succeeded',
        attributes: {
          accountType: 'loan_installment',
          loanProductId: INSTALLMENTS_PRODUCT_ID,
          digitalServiceId: 'service_1',
          value: 10,
        },
        properties: {
          eventId,
        },
        sendAsConversionEventToMobileApp: true,
      });
    }

    // Trigger Impact "Initial Installment Payment" action
    impact.trackInitialPayment({
      customerId: username,
      customerEmail: email,
    });

    logOnboardingEvent({ eventName: 'payment completed', email: email! });
  };
};
