import { Box, Typography } from '@mui/material';
import { Redirect, useParams } from 'react-router-dom';

import OnboardingPage from 'components/OnboardingPage';
import PageLoader from 'components/PageLoader';
import { ROUTES } from 'components/Routes';
import { theme } from 'context/ThemeProvider';
import useApplicationStatusV2, {
  ApplicationStatusResponse,
} from 'hooks/useApplicationStatusV2';
import { ApplicationSubmissionResponse } from 'hooks/useApplicationSubmitV2';

/**
 * This is a temporary solution. Currently, ApplicationStatus is synchronous
 * and we need to wait for it to be updated before redirecting to apply.
 * We can also check ApplicationSubmission for status and rejection code in the else case.
 *
 * TODO: Remove this once ApplicationStatus is updated to be asynchronous.
 *
 * @param applicationSubmission ApplicationSubmissionResponse
 * @param applicationStatus ApplicationStatusResponse
 * @returns boolean
 */
const isApplicationRejectedByKyc = (
  applicationSubmission: ApplicationSubmissionResponse,
  applicationStatus: ApplicationStatusResponse,
) => {
  if (
    applicationSubmission.status === 'REJECTED' &&
    applicationSubmission.rejection?.code &&
    applicationSubmission.rejection?.code.type === 'KYC' &&
    ['ADDRESS_VERIFICATION', 'IDENTITY_VERIFICATION'].includes(
      applicationSubmission.rejection?.code.subCode,
    )
  ) {
    return true;
  }

  if (
    applicationStatus.status === 'REJECTED' &&
    applicationStatus.rejection?.code &&
    applicationStatus.rejection?.code.type === 'KYC' &&
    ['ADDRESS_VERIFICATION', 'IDENTITY_VERIFICATION'].includes(
      applicationStatus.rejection?.code.subCode,
    )
  ) {
    return true;
  }

  return false;
};

const ApplicationRejectedV2: React.FC = () => {
  const { createdAt } = useParams<{ createdAt: string }>();
  const { data, isLoading } = useApplicationStatusV2({
    createdAt,
  });

  if (isLoading) {
    return <PageLoader />;
  }

  if (
    data &&
    data.status === 'REJECTED' &&
    data.rejection?.code?.type === 'KYC' &&
    ['ADDRESS_VERIFICATION', 'IDENTITY_VERIFICATION'].includes(
      data.rejection?.code.subCode,
    )
  ) {
    return <Redirect to={ROUTES.APPLY} />;
  }

  return (
    <OnboardingPage supportSubject="application rejected">
      <Typography variant="h1" marginBottom={theme.spacing(2)}>
        Unable to proceed with your application
      </Typography>

      <Typography marginBottom={theme.spacing(1)}>
        Thanks for applying to the Installment Plan.
      </Typography>
      <Typography marginBottom={theme.spacing(1)}>
        After reviewing your background verification, we are unable to proceed
        with your application. If you would like a statement of specific
        reasons, please contact us below within 60 days of the date of this
        notice. We will provide you with the statement of reasons within 30 days
        after receiving your request.
      </Typography>

      <Box sx={{ paddingLeft: theme.spacing(2) }}>
        <Typography>Kovo Inc.</Typography>
        <Typography>9450 SW Gemini Dr, Suite 87907</Typography>
        <Typography>Beaverton, OR 97008-7105</Typography>
        <Typography marginBottom={theme.spacing(1)}>(855) 965-5686</Typography>
      </Box>

      <Typography marginBottom={theme.spacing(5)}>
        <strong>Please note</strong>: We did <strong>not</strong> run a credit
        check or review your credit report.
      </Typography>

      <Typography>
        <b>Notice:</b>&nbsp;The Federal Equal Credit Opportunity Act prohibits
        creditors from discriminating against credit applicants on the basis of
        race, color, religion, national origin, sex, marital status, age
        (provided the applicant has the capacity to enter into a binding
        contract); because all or part of the applicant's income derives from
        any public assistance program; or because the applicant has in good
        faith exercised any right under the Consumer Credit Protection Act. The
        Federal agency that administers compliance with this law concerning this
        creditor is The Federal Trade Commission, Equal Credit Opportunity, 600
        Pennsylvania Avenue, NW, Washington, DC 20580.
      </Typography>
    </OnboardingPage>
  );
};

export default ApplicationRejectedV2;
