import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Link as MuiLink,
  Popover,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { theme } from 'context/ThemeProvider';
import { isValidUrl } from 'helpers/string';

import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import type { Offer } from '../OffersList';
import OfferContentListItem from './OfferContentListItem';
import React from 'react';

interface Props {
  userId?: string;
  offer: Offer;
}

interface OfferDetails {
  title: string;
  value: string;
}

const OfferCard: React.FC<Props> = ({ userId, offer }) => {
  const { name, offerConfig, categoryName, affiliateLink, categoryId, type } =
    offer;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { track } = useProductAnalytics();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    track({
      namespace: 'ui',
      event: 'offers.offer-card.disclosure.clicked',
      attributes: {
        offerId: offer.id,
        offerName: offer.name,
      },
    });
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getFormattedAffiliateLink = () => {
    const affiliateLinkUrl = new URL(affiliateLink);

    if (!userId) {
      return affiliateLink;
    }

    // these are non-Impact links
    if (['Credible', 'LendingUSA'].includes(name)) {
      affiliateLinkUrl.searchParams.append(
        'utm_content',
        `${categoryId}|${userId}`,
      );
    } else if (name === 'Answer Financial') {
      affiliateLinkUrl.searchParams.append('CID', `${categoryId}|${userId}`);
    } else if (['Tresl', 'AutoPay', 'RateGenius'].includes(name)) {
      affiliateLinkUrl.searchParams.append('partnerRecordIdentifier', userId);
    } else if (name === 'Insurify') {
      affiliateLinkUrl.searchParams.append('utm_campaign', 'web-app');
      affiliateLinkUrl.searchParams.append(
        'utm_content',
        `${categoryId}|${userId}`,
      );
      affiliateLinkUrl.searchParams.append('click_id', userId);
    } else if (name === 'Financeify') {
      affiliateLinkUrl.searchParams.append('AffiliateReferenceID', userId);
    } else {
      // these are Impact and OppFi links
      affiliateLinkUrl.searchParams.append('partnercustid', userId);
      affiliateLinkUrl.searchParams.append('subId1', categoryId);
      affiliateLinkUrl.searchParams.append('subId2', userId);
    }

    return affiliateLinkUrl.href;
  };

  const offerDetails = (): Array<OfferDetails> => {
    const details: Array<OfferDetails> = [];
    if (type === 'credit_card') {
      if (offerConfig.annualFee) {
        details.push({ title: 'Annual Fee', value: offerConfig.annualFee });
      }
      if (offerConfig.apr) {
        details.push({ title: 'APR Range', value: offerConfig.apr });
      }
      if (offerConfig.creditScoreRange) {
        details.push({
          title: 'Credit Score',
          value: offerConfig.creditScoreRange,
        });
      }
    } else if (type === 'loan') {
      if (offerConfig.fundingRange) {
        details.push({
          title: 'Loan Amount',
          value: offerConfig.fundingRange,
        });
      }
      if (offerConfig.apr) {
        details.push({ title: 'APR Range', value: offerConfig.apr });
      }
      if (offerConfig.loanTerm) {
        details.push({ title: 'Loan Term', value: offerConfig.loanTerm });
      }
      if (offerConfig.creditScoreRange) {
        details.push({
          title: 'Credit Score',
          value: offerConfig.creditScoreRange,
        });
      }
    }

    return details;
  };

  const shouldShowOfferDetails =
    offer.type === 'credit_card' || offer.type === 'loan';

  return (
    <Card
      elevation={0}
      sx={{
        textAlign: 'center',
      }}
    >
      {offerConfig.titleImageUrl && (
        <Box
          component="img"
          src={offerConfig.titleImageUrl}
          alt={`${name} logo`}
          sx={{
            marginBottom: theme.spacing(2),
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '200px',
            maxHeight: '100px',
          }}
        />
      )}

      {offerConfig.imageUrl && (
        <Box
          component="img"
          src={offerConfig.imageUrl}
          alt={`${name} logo`}
          sx={{
            marginBottom: theme.spacing(2),
            maxWidth: offer.type === 'credit_card' ? '200px' : undefined,
            maxHeight: '100px',
          }}
        />
      )}

      <CardContent
        sx={{
          marginBottom: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" marginBottom={theme.spacing(userId ? 2 : 1)}>
          {name}
        </Typography>

        {/* Only show category name on the public page, meaning user isn't logged in */}
        {!userId && (
          <Typography variant="subtitle2" marginBottom={theme.spacing(2)}>
            {categoryName}
          </Typography>
        )}

        {offerConfig.description && (
          <Typography variant="footnote" marginBottom={theme.spacing(2)}>
            {offerConfig.description.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        )}

        {shouldShowOfferDetails && (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns:
                type === 'credit_card' ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)',
              gap: theme.spacing(2),
            }}
          >
            {offerDetails().map((detail) => (
              <OfferContentListItem
                key={detail.title}
                width={type === 'credit_card' ? '84px' : '120px'}
                title={detail.title}
                value={detail.value}
              />
            ))}
          </Box>
        )}
      </CardContent>

      <CardActions
        sx={{
          marginTop: theme.spacing(2),
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Impact query string docs: https://impact-helpdesk.freshdesk.com/support/solutions/articles/48001164789-add-query-string-parameters-to-tracking-links-as-a-partner?accountType=PARTNER#query-string-parameters-0-1 */}
        <Button
          href={getFormattedAffiliateLink()}
          variant="outlined-gradient"
          target="_blank"
          rel="noopener noreferrer"
          fullWidth
          onClick={() => {
            track({
              namespace: 'ui',
              event: 'offers.offer-card.visit-site.clicked',
              attributes: {
                offerId: offer.id,
                offerName: offer.name,
              },
            });
          }}
        >
          Visit Site
        </Button>

        {offerConfig.disclosure && (
          <Box marginTop={theme.spacing(2)}>
            {isValidUrl(offerConfig.disclosure) ? (
              <MuiLink
                underline="always"
                href={offerConfig.disclosure}
                target="_blank"
                onClick={() => {
                  track({
                    namespace: 'ui',
                    event: 'offers.offer-card.disclosure.clicked',
                    attributes: {
                      offerId: offer.id,
                      offerName: offer.name,
                    },
                  });
                }}
              >
                <Typography variant="caption">Disclosure</Typography>
              </MuiLink>
            ) : (
              <>
                <MuiLink
                  component="button"
                  variant="caption"
                  onClick={handleClick}
                >
                  Disclosure
                </MuiLink>

                <Popover
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box padding={theme.spacing(1, 2)} maxWidth="400px">
                    <Typography variant="caption">
                      {offerConfig.disclosure}
                    </Typography>
                  </Box>
                </Popover>
              </>
            )}
          </Box>
        )}
      </CardActions>
    </Card>
  );
};

export default OfferCard;
