import config from 'config';
import { LoadingButton } from '@mui/lab';
import { Alert, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { useEffect, useRef } from 'react';
import * as yup from 'yup';

import {
  BIRTHDATE_INPUT_LABEL,
  BirthdateInput,
  birthdateInputSchema,
} from 'components/shared/BirthdateInput';
import Checkbox from 'components/shared/Checkbox';
import CurrencyInput from 'components/shared/CurrencyInput';
import SsnInput from 'components/shared/SsnInput';
import TextField from 'components/shared/TextField';
import { theme } from 'context/ThemeProvider';
import { ADDRESS_FORM_FIELDS } from 'helpers/addressFormUtils';
import { ZIP_CODE_REGEX } from 'helpers/constants';
import { FormField } from 'helpers/form';
import { validateSSNorITIN } from 'helpers/validateSsnItin';
import useApplicationSubmitV2 from 'hooks/useApplicationSubmitV2';
import { INSTALLMENTS_PRODUCT_ID } from 'types/schemas';
import ConfirmSsnDobDialog from './ConfirmSsnDobDialog';
import InvalidAddressDialog, { AddressData } from './InvalidAddressDialog';
import RecommendedAddressDialog from './RecommendedAddressDialog';
import ConfirmDobDialog from './ConfirmDobDialog';
import useCurrentUser from 'hooks/queries/useCurrentUser';
import { ROUTES } from 'components/Routes';
import { useHistory } from 'react-router-dom';
import { isUseSsnServiceFeatureFlagEnabled } from 'helpers/useSsnServiceFeatureFlag';
import ConfirmNameSsnDobDialog from './ConfirmNameSsnDobDialog';
export interface FormValues {
  firstName: string;
  middleName: string;
  lastName: string;
  birthday: string;
  address: string;
  address2: string;
  state: string;
  city: string;
  zipcode: string;
  ssn: string;
  monthlyIncome: string;
  confirmDisclosure: boolean;
  isRecommendedAddress?: boolean;
}

// Regex to validate name fields - allows letters, international characters, accents, and specific special characters
// but disallows numbers and other special characters
const NAME_REGEX = /^[\p{L}\p{M}.,'\-\s]+$/u;
const NAME_REGEX_ERROR_MESSAGE =
  'Allowed: hyphens, apostrophes, periods, commas';

const ApplyForm: React.FC = () => {
  const { track } = useProductAnalytics();
  const addressInputRef = useRef<HTMLInputElement>(null);
  const { isLoading, data: userData } = useCurrentUser();
  const history = useHistory();
  if (
    !isLoading &&
    userData?.isDuplicateUser &&
    isUseSsnServiceFeatureFlagEnabled()
  ) {
    history.push(ROUTES.ACCOUNT_EXISTS_ROUTE);
  }

  const DEFAULT_VALUES: FormValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    birthday: '',
    address: '',
    address2: '',
    state: '',
    city: '',
    zipcode: '',
    ssn: '',
    monthlyIncome: '',
    confirmDisclosure: false,
  };

  const GENERIC_SUPPORT_ERROR_MESSAGE =
    'An error occurred while submitting your application. Please contact support@kovocredit.com for assistance.';

  const getInitialValuesFromURL = (): FormValues => {
    const params = new URLSearchParams(window.location.search);
    const isDev = config.VITE_STAGE !== 'prod';

    if (isDev || typeof window === 'undefined') {
      return DEFAULT_VALUES;
    }

    return {
      firstName: params.get('firstName') || '',
      middleName: params.get('middleName') || '',
      lastName: params.get('lastName') || '',
      birthday: params.get('birthday') || '',
      address: params.get('address') || '',
      address2: params.get('address2') || '',
      state: params.get('state') || '',
      city: params.get('city') || '',
      zipcode: params.get('zipcode') || '',
      ssn: params.get('ssn') || '',
      monthlyIncome: params.get('monthlyIncome') || '',
      confirmDisclosure: false,
    };
  };

  const INITIAL_VALUES = getInitialValuesFromURL();

  const FORM_FIELDS: FormField[] = [
    {
      name: 'firstName',
      label: 'First name',
    },
    {
      name: 'middleName',
      label: 'Middle name (optional)',
    },
    {
      name: 'lastName',
      label: 'Last name',
    },
    {
      name: 'birthday',
      label: BIRTHDATE_INPUT_LABEL,
      Component: BirthdateInput,
    },
    ...ADDRESS_FORM_FIELDS,
    {
      name: 'ssn',
      label: 'SSN',
      Component: SsnInput,
    },
    {
      name: 'monthlyIncome',
      label: 'Monthly income ($)',
      Component: CurrencyInput,
    },
  ];

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .min(2, 'First name is too short')
      .required('First name is required')
      .test(
        'valid-name-format',
        NAME_REGEX_ERROR_MESSAGE,
        (value) => !value || NAME_REGEX.test(value),
      ),
    middleName: yup
      .string()
      .optional()
      .test(
        'valid-name-format',
        NAME_REGEX_ERROR_MESSAGE,
        (value) => !value || NAME_REGEX.test(value),
      ),
    lastName: yup
      .string()
      .min(2, 'Last name is too short')
      .required('Last name is required')
      .test(
        'valid-name-format',
        NAME_REGEX_ERROR_MESSAGE,
        (value) => !value || NAME_REGEX.test(value),
      ),
    birthday: birthdateInputSchema,
    address: yup.string().required('Street address is required'),
    state: yup.string().required('State is required'),
    city: yup.string().required('City is required'),
    zipcode: yup
      .string()
      .required('Zip code is required')
      .test('zipcode', 'Invalid zip code', (value = '') =>
        ZIP_CODE_REGEX.test(value),
      ),
    ssn: yup
      .string()
      .required('Social security number is required')
      .test(
        'ssn',
        'Please enter a valid Social Security Number',
        validateSSNorITIN,
      ),
    monthlyIncome: yup
      .string()
      .required('Income is required')
      .test(
        'notZero',
        'Monthly income cannot be $0',
        (value) => value !== '$0',
      ),
    confirmDisclosure: yup.boolean().oneOf([true], 'Authorization is required'),
  });

  const onSubmit = (values: FormValues) => {
    track({
      namespace: 'application',
      event: 'submission.initiated',
      attributes: {
        accountType: 'loan_installment',
        loanProductId: INSTALLMENTS_PRODUCT_ID,
        digitalServiceId: 'service_1',
      },
    });
    mutateV2(values);
  };

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setTouched,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit,
    validateOnBlur: false,
  });

  const handleAddressSelect = ({
    address,
    address2,
    city,
    state,
    zipcode,
  }: Partial<AddressData>) => {
    setFieldValue('address', address || '');
    setFieldValue('address2', address2 || '');
    setFieldValue('city', city || '');
    setFieldValue('state', state || '');
    setFieldValue('zipcode', zipcode || '');
  };

  const {
    data: applicationSubmissionDataV2,
    mutate: mutateV2,
    isLoading: isLoadingV2,
    error: applicationSubmitErrorV2,
    reset: resetV2,
  } = useApplicationSubmitV2();

  const hasApplicationSubmitErrorV2 = () => {
    return (
      applicationSubmissionDataV2?.status === 'REJECTED' ||
      applicationSubmitErrorV2
    );
  };

  const handleCloseDialog = () => {
    resetV2();
    addressInputRef.current?.focus();
  };

  const handleAddressConfirm = (addressValues: AddressData) => {
    handleAddressSelect(addressValues);
    mutateV2({
      ...values,
      ...addressValues,
      address2: addressValues.address2 || '',
      isRecommendedAddress: true,
    });
    window.scrollTo(0, document.body.scrollHeight);
  };

  useEffect(() => {
    if (
      applicationSubmissionDataV2?.rejection?.code.subCode ===
        'DUPLICATE_APPLICATION' &&
      !isUseSsnServiceFeatureFlagEnabled()
    ) {
      window.scrollTo(0, 0);
    }
  }, [applicationSubmissionDataV2]);

  const renderErrorGroupV2 = () => {
    if (
      applicationSubmissionDataV2?.rejection?.code.type === 'KYC' &&
      applicationSubmissionDataV2?.rejection?.code.subCode ===
        'ADDRESS_VERIFICATION' &&
      !!applicationSubmissionDataV2?.rejection?.addressConfirmation &&
      !!applicationSubmissionDataV2?.rejection?.addressConfirmation?.provided &&
      !!applicationSubmissionDataV2?.rejection?.addressConfirmation?.suggested
    ) {
      return (
        <RecommendedAddressDialog
          open={true}
          originalAddress={{
            address: values.address,
            address2: values.address2,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
          }}
          formattedAddress={
            applicationSubmissionDataV2?.rejection?.addressConfirmation
              ?.suggested
          }
          onConfirm={handleAddressConfirm}
          onClose={handleCloseDialog}
        />
      );
    }

    return (
      <>
        <InvalidAddressDialog
          open={
            applicationSubmissionDataV2?.rejection?.code.type === 'KYC' &&
            applicationSubmissionDataV2?.rejection?.code.subCode ===
              'ADDRESS_VERIFICATION'
          }
          address={values.address}
          address2={values.address2}
          city={values.city}
          state={values.state}
          zipcode={values.zipcode}
          issues={
            applicationSubmissionDataV2?.rejection?.addressValidationIssues ??
            []
          }
          missingComponents={
            applicationSubmissionDataV2?.rejection?.missingComponentTypes ?? []
          }
          onClose={handleCloseDialog}
        />

        <ConfirmSsnDobDialog
          open={
            applicationSubmissionDataV2?.rejection?.code.type === 'KYC' &&
            applicationSubmissionDataV2?.rejection?.code.subCode ===
              'IDENTITY_VERIFICATION'
          }
          values={values}
          touched={touched}
          errors={errors}
          setTouched={setTouched}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          onConfirm={() => {
            if (!isLoadingV2) {
              handleSubmit();
            }
          }}
          onClose={handleCloseDialog}
        />

        <ConfirmNameSsnDobDialog
          open={
            applicationSubmissionDataV2?.rejection?.code.type === 'KYC' &&
            applicationSubmissionDataV2?.rejection?.code.subCode ===
              'UNCLAIMABLE_SSN'
          }
          values={values}
          touched={touched}
          errors={errors}
          setTouched={setTouched}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          onConfirm={() => {
            if (!isLoadingV2) {
              handleSubmit();
            }
          }}
          onClose={handleCloseDialog}
        />

        <ConfirmDobDialog
          open={
            applicationSubmissionDataV2?.rejection?.code.type === 'KYC' &&
            applicationSubmissionDataV2?.rejection?.code.subCode ===
              'UNPROCESSABLE_APPLICATION'
          }
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          onConfirm={() => {
            if (!isLoadingV2) {
              handleSubmit();
            }
          }}
          onClose={handleCloseDialog}
        />

        {(!isUseSsnServiceFeatureFlagEnabled() ||
          !userData?.isDuplicateUser ||
          !userData?.existingUserEmail) &&
          applicationSubmissionDataV2?.rejection?.code.subCode ===
            'DUPLICATE_APPLICATION' && (
            <Alert
              severity="error"
              icon={false}
              sx={{ marginBottom: theme.spacing(5) }}
            >
              User already exists. Please contact support@kovocredit.com for
              assistance.
            </Alert>
          )}

        {!(
          applicationSubmissionDataV2?.rejection?.code.type === 'KYC' &&
          [
            'IDENTITY_VERIFICATION',
            'UNPROCESSABLE_APPLICATION',
            'ADDRESS_VERIFICATION',
            'DUPLICATE_APPLICATION',
          ].includes(applicationSubmissionDataV2?.rejection?.code.subCode)
        ) && (
          <Alert
            severity="error"
            icon={false}
            sx={{ marginBottom: theme.spacing(5) }}
          >
            {applicationSubmitErrorV2?.displayMessage ||
              GENERIC_SUPPORT_ERROR_MESSAGE}

            {applicationSubmitErrorV2?.metadata?.errors?.map((error) =>
              Object.values(error || {}).map((errorMessages) =>
                (errorMessages || []).map((errorMessage) => (
                  <Typography variant="body2" key={errorMessage}>
                    {errorMessage}
                  </Typography>
                )),
              ),
            )}
          </Alert>
        )}
      </>
    );
  };

  return (
    <>
      {hasApplicationSubmitErrorV2() && renderErrorGroupV2()}

      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={{ xs: 2, sm: 3 }}
          marginBottom={theme.spacing(3)}
        >
          {FORM_FIELDS.map(
            ({ name, label, Component = TextField, helperText }, index) => {
              const props = {
                name,
                label,
                value: (values as any)[name],
                error: !!((touched as any)[name] && (errors as any)[name]),
                errorText: (errors as any)[name],
                helperText,
                setFieldValue,
                onAddressSelect: handleAddressSelect,
                onChange: handleChange,
                onBlur: handleBlur,
                fullWidth: true,

                ...(name === 'zipcode' && {
                  type: 'tel' as 'tel',
                  pattern: '[0-9-]*',
                  maxLength: 5,
                }),

                ...(name === 'address' && {
                  addressInputRef,
                }),

                ...(index === 0 && {
                  autoFocus: true,
                }),
              };

              return (
                <Grid item xs={12} key={name}>
                  <Component {...props} />
                </Grid>
              );
            },
          )}
        </Grid>

        <Checkbox
          label="Authorization for the Social Security Administration to Disclose Your Social Security Number Verification."
          labelProps={{
            sx: {
              fontWeight: 'bold',
            },
          }}
          name="confirmDisclosure"
          checked={values.confirmDisclosure}
          error={!!(touched.confirmDisclosure && errors.confirmDisclosure)}
          errorText={errors.confirmDisclosure}
          onChange={(e) => {
            track({
              namespace: 'ui',
              event: 'apply.ssn-disclosure.checked',
              attributes: {
                checked: e.target.checked,
              },
            });

            handleChange(e);
          }}
          sx={{ marginBottom: theme.spacing(4) }}
          showBorder
        />

        <Typography variant="footnote" marginBottom={theme.spacing(3)}>
          I authorize the Social Security Administration (SSA) to verify and
          disclose to Kovo Inc. through SentiLink Verification Services Corp.,
          their service provider for the purpose of this transaction whether the
          name, Social Security Number (SSN) and date of birth I have submitted
          matches information in SSA records. My consent is for a one-time
          validation within the next day.
        </Typography>

        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoadingV2}
          fullWidth
          sx={{ marginBottom: theme.spacing(3) }}
        >
          Submit
        </LoadingButton>
      </form>
    </>
  );
};

export default ApplyForm;
