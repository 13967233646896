import {
  FilledInput,
  FormControl,
  FormHelperText,
  InputBaseComponentProps,
  InputLabel,
  SxProps,
} from '@mui/material';

import { DARK_BLUE } from 'context/ThemeProvider';

interface Props {
  name: string;
  type?: 'text' | 'email' | 'password' | 'tel' | 'number';
  pattern?: string;
  maxLength?: number;
  label: string;
  value: string;
  helperText?: string | React.ReactNode;
  error?: boolean;
  errorText?: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.ChangeEvent<any>) => void;
  endAdornment?: React.ReactNode;
  fullWidth?: boolean;
  sx?: SxProps;
  autoComplete?:
    | 'one-time-code'
    | 'new-password'
    | 'current-password'
    | 'email';
  autoFocus?: boolean;
  inputProps?: InputBaseComponentProps;
}

const TextField: React.FC<Props> = ({
  name,
  type = 'text',
  pattern,
  maxLength,
  label,
  value,
  helperText,
  error,
  errorText,
  onChange,
  onBlur,
  endAdornment,
  fullWidth,
  sx,
  autoComplete,
  autoFocus,
  inputProps,
}) => {
  return (
    <FormControl
      error={error}
      sx={{ position: 'relative', ...sx }}
      fullWidth={fullWidth}
      variant="filled"
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>

      <FilledInput
        id={name}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        endAdornment={endAdornment}
        inputProps={{
          pattern,
          maxLength,
          autoFocus,
          ...inputProps,
        }}
        autoComplete={autoComplete}
      />
      {!errorText && helperText && (
        <FormHelperText
          component="div"
          sx={{
            position: 'absolute',
            top: '100%',
            marginTop: '2px',
            color: DARK_BLUE,

            '&.Mui-error': {
              color: DARK_BLUE,
            },
          }}
        >
          {helperText}
        </FormHelperText>
      )}
      {error && errorText && (
        <FormHelperText
          sx={{
            position: 'absolute',
            top: '100%',
            marginTop: '2px',
          }}
        >
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default TextField;
