import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Checkbox from 'components/shared/Checkbox';

import KovoDialog from 'components/KovoDialog';
import { theme } from 'context/ThemeProvider';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { useEffect } from 'react';
import { FormField } from 'helpers/form';
import BirthdateInput, {
  BIRTHDATE_INPUT_LABEL,
} from 'components/shared/BirthdateInput';
import SsnInput from 'components/shared/SsnInput';
import {
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { FormValues } from '../ApplyForm';
import TextField from 'components/shared/TextField';

interface DialogProps extends ContentProps {
  open: boolean;
  onClose: () => void;
}

interface ContentProps {
  onConfirm: () => void;
  setFieldValue: (field: string, value: any) => void;
  setTouched: (touched: Partial<Record<keyof FormValues, boolean>>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  touched: Partial<Record<keyof FormValues, boolean>>;
  errors: Partial<Record<keyof FormValues, string>>;
  values: FormValues;
}

export const ConfirmNameSsnDobDialogContent: React.FC<ContentProps> = ({
  onConfirm,
  setTouched,
  setFieldValue,
  handleChange,
  handleBlur,
  touched,
  errors,
  values,
}) => {
  const { track } = useProductAnalytics();

  useEffect(() => {
    track({
      namespace: 'ui',
      event: 'apply.name-ssn-dob-confirmation.shown',
    });

    setFieldValue('firstName', '');
    setFieldValue('lastName', '');
    setFieldValue('birthday', '');
    setFieldValue('ssn', '');
    setFieldValue('confirmDisclosure', false);
    setTouched({
      birthday: false,
      ssn: false,
      confirmDisclosure: false,
    });
  }, []);

  const FORM_FIELDS: FormField[] = [
    {
      name: 'firstName',
      label: 'First Name',
    },
    {
      name: 'lastName',
      label: 'Last Name',
    },
    {
      name: 'birthday',
      label: BIRTHDATE_INPUT_LABEL,
      Component: BirthdateInput,
    },
    {
      name: 'ssn',
      label: 'SSN',
      Component: SsnInput,
    },
  ];

  return (
    <Box sx={{ overflow: 'auto' }}>
      <DialogTitle variant="h4">
        Confirm your information
        <Typography variant="body1" marginTop={theme.spacing(2)}>
          Please re-enter your information in order to verify your identity.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={{ xs: 2, sm: 3 }}
          marginBottom={theme.spacing(3)}
        >
          {FORM_FIELDS.map(
            ({ name, label, Component = TextField, helperText }, index) => {
              const props = {
                name,
                label,
                value: (values as any)[name],
                error: !!((touched as any)[name] && (errors as any)[name]),
                errorText: (errors as any)[name],
                helperText,
                setFieldValue,
                onChange: handleChange,
                onBlur: handleBlur,
                fullWidth: true,
                ...(index === 0 && {
                  autoFocus: true,
                }),
              };

              return (
                <Grid item xs={12} key={name}>
                  <Component {...props} />
                </Grid>
              );
            },
          )}
        </Grid>

        <Checkbox
          label="Authorization for the Social Security Administration to Disclose Your Social Security Number Verification."
          labelProps={{
            sx: {
              fontWeight: 'bold',
            },
          }}
          name="confirmDisclosure"
          checked={values.confirmDisclosure}
          error={!!(touched.confirmDisclosure && errors.confirmDisclosure)}
          errorText={errors.confirmDisclosure}
          onChange={(e) => {
            track({
              namespace: 'ui',
              event: 'apply.ssn-dob-confirm-dialog-disclosure.checked',
              attributes: {
                checked: e.target.checked,
              },
            });

            handleChange(e);
          }}
          sx={{ marginBottom: theme.spacing(2) }}
          showBorder
        />

        <Typography variant="footnote">
          I authorize the Social Security Administration (SSA) to verify and
          disclose to Kovo Inc. through SentiLink Verification Services Corp.,
          their service provider for the purpose of this transaction whether the
          name, Social Security Number (SSN) and date of birth I have submitted
          matches information in SSA records. My consent is for a one-time
          validation within the next day.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ paddingX: theme.spacing(0) }}>
        <Button variant="contained" onClick={onConfirm} fullWidth>
          Confirm
        </Button>
      </DialogActions>
      <Typography variant="footnote">
        By clicking the CONFIRM button, you are signing the consent for SSA to
        disclose your SSN Verification to Kovo Inc. and SentiLink Verification
        Services Corp. You agree that your electronic signature has the same
        legal meaning, validity, and effect as your handwritten signature.
      </Typography>
    </Box>
  );
};

const ConfirmNameSsnDobDialog: React.FC<DialogProps> = ({
  open,
  onConfirm,
  onClose,
  setTouched,
  setFieldValue,
  handleChange,
  handleBlur,
  touched,
  errors,
  values,
}) => {
  return (
    <KovoDialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      closeIcon={<ArrowBackIcon />}
      sx={{
        margin: theme.spacing(-2),
      }}
      scroll="body"
    >
      <ConfirmNameSsnDobDialogContent
        onConfirm={onConfirm}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
        values={values}
        setTouched={setTouched}
      />
    </KovoDialog>
  );
};

export default ConfirmNameSsnDobDialog;
