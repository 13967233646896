import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import CopySuccessOverlay from 'components/CopySuccessOverlay';
import OnboardingPage from 'components/OnboardingPage';
import { theme } from 'context/ThemeProvider';
import { copyText } from 'helpers/copyText';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import useCurrentUser from 'hooks/queries/useCurrentUser';
import PageLoader from 'components/PageLoader';
import useLogOut from 'hooks/useLogOut';
import { ROUTES } from 'components/Routes';
import { ACCOUNT_EXISTS_EMAIL_KEY } from 'helpers/constants';

export const PLACEHOLDER_EMAIL = '';
export const EMAIL_PARAM_NAME = 'email';

const AccountExistsPage: React.FC = () => {
  const [showEmailButtonCopied, setShowEmailButtonCopied] = useState(false);
  const { data: userData, isLoading } = useCurrentUser();
  const { track } = useProductAnalytics();
  const { mutate: logout, isLoading: isLoadingLogout } = useLogOut();

  const existingEmail = userData?.existingUserEmail || '';

  useEffect(() => {
    if (showEmailButtonCopied) {
      const timeout = setTimeout(() => {
        setShowEmailButtonCopied(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [showEmailButtonCopied]);

  const handleCopyEmail = async () => {
    const result = await copyText(existingEmail);

    if (!result) {
      return;
    }

    track({
      namespace: 'ui',
      event: 'account-exists.copy-email.clicked',
    });
    setShowEmailButtonCopied(true);
  };

  if (isLoading || isLoadingLogout) {
    return <PageLoader />;
  }

  if (!isLoading && !isLoadingLogout && !existingEmail) {
    return <Redirect to={ROUTES.APP} />;
  }

  const logOutOnboardingAndRedirectToLogin = async () => {
    sessionStorage.setItem(ACCOUNT_EXISTS_EMAIL_KEY, existingEmail);
    window.posthog?.reset();
    track({
      namespace: 'ui',
      event: 'account-exists.login.clicked',
    });

    await logout();
  };

  return (
    <OnboardingPage linkLogoToHomepage supportSubject="forgot password">
      <Typography variant="h1">Existing account found</Typography>

      <Typography
        variant="body1"
        marginTop={theme.spacing(2)}
        marginBottom={theme.spacing(2)}
      >
        It looks like you already have an account. Please log in with the
        following email.
      </Typography>

      <Box
        sx={{
          position: 'relative',
          boxSizing: 'border-box',
          display: 'block',
          marginY: theme.spacing(3),
        }}
      >
        <Button
          fullWidth
          onClick={() => handleCopyEmail()}
          sx={{
            paddingY: theme.spacing(2),
            borderRadius: theme.spacing(1),
            border: !showEmailButtonCopied
              ? `1px solid ${theme.palette.primary.main}`
              : '',
            textTransform: 'none',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          <Typography variant="body1" fontWeight={700}>
            {existingEmail}
          </Typography>
        </Button>
        {showEmailButtonCopied && (
          <CopySuccessOverlay borderRadius={theme.spacing(1)} />
        )}
      </Box>

      <Button
        variant="contained"
        fullWidth
        onClick={logOutOnboardingAndRedirectToLogin}
      >
        Log in
      </Button>
    </OnboardingPage>
  );
};

export default AccountExistsPage;
