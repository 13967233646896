// TODO: move to hooks/mutations folder

import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { FormValues } from 'components/ApplyForm/ApplyForm';
import { AddressData } from 'components/ApplyForm/InvalidAddressDialog';
import { removeCookie } from 'helpers/cookies';
import { USE_APPLICATION_STATUS_V2_QUERY_KEY } from 'hooks/queryKeys';
import { KovoError } from 'libs/KovoError';
import { logOnboardingEvent } from 'libs/logger';
import { DateTime } from 'luxon';
import {
  ForgotEmailResponse,
  INSTALLMENTS_PRODUCT_ID,
  ProductTypes,
  User,
} from 'types/schemas';
import useCurrentUser, {
  USE_CURRENT_USER_QUERY_KEY,
} from './queries/useCurrentUser';
import { useClientsService } from './useClientsService';
import useLogOut from './useLogOut';
import { isUseSsnServiceFeatureFlagEnabled } from 'helpers/useSsnServiceFeatureFlag';

type AddressErrorMetadata = {
  issues?: { componentType: string; confirmationLevel: string }[];
  missingComponentTypes?: string[];
  formattedAddress?: AddressData;
  errors?: { [key: string]: string[] }[];
};

// IDV Migration, new types
export type ApplicationSubmissionRequest = {
  userId: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dob: string;
  socialSecurityNumber: string;
  income: number;
} & AddressData;

export type ApplicationSubmissionStatus = 'SUBMITTED' | 'REJECTED';

export type RejectionCode =
  | {
      type: 'KYC';
      subCode: KYCSubcode;
    }
  | {
      type: 'ADVERSE_ACTION';
      subCode: AdverseActionSubcode;
    };

type KYCSubcode =
  | 'UNPROCESSABLE_APPLICATION'
  | 'IDENTITY_VERIFICATION'
  | 'ADDRESS_VERIFICATION'
  | 'UNVERIFIABLE'
  | 'DUPLICATE_APPLICATION'
  | 'UNCLAIMABLE_SSN';

type AdverseActionSubcode = 'AGE' | 'INCOME' | 'WATCHLIST';

type DuplicateIdFailureSchema = {
  rejectedEmail: string;
  existingEmail: string;
};

export type RejectionDetails = {
  code: RejectionCode;
  addressConfirmation?: {
    provided: AddressData;
    suggested: AddressData;
  };
  addressValidationIssues?: any[];
  missingComponentTypes?: string[];
  message?: string;
  duplicateIdFailure?: DuplicateIdFailureSchema;
};

export type ApplicationSubmissionResponse = {
  accountType: string;
  createdAt: string;
  status: ApplicationSubmissionStatus;
  rejection?: RejectionDetails;
};

export class KovoAddressUpdateError extends KovoError<AddressErrorMetadata> {}

const formatData = (data: FormValues) => {
  const income = data?.monthlyIncome
    ? parseInt(data.monthlyIncome.replace('$', '').replace(',', ''), 10)
    : 10;
  const dob = DateTime.fromFormat(data.birthday, 'MM-dd-yyyy').toFormat(
    'yyyy-MM-dd',
  );
  return {
    userId: '',
    firstName: data.firstName,
    middleName: data.middleName,
    lastName: data.lastName,
    dob,
    socialSecurityNumber: data.ssn,
    income,
    address: data.address,
    address2: data.address2 || '',
    city: data.city,
    state: data.state,
    zipcode: data.zipcode,
    isRecommendedAddress: data?.isRecommendedAddress,
  };
};

function useApplicationSubmitV2() {
  const { data: user } = useCurrentUser();
  const email = user?.currentEmail || '';
  const phoneNumber = user?.phoneNumber || '';
  const identityId = user?.userId || '';

  const history = useHistory();
  const queryClient = useQueryClient();
  const { track } = useProductAnalytics();
  const clientsService = useClientsService();

  const submitApplication = async (
    values: FormValues,
    accountType?: ProductTypes,
  ): Promise<ApplicationSubmissionResponse> => {
    const body: ApplicationSubmissionRequest = {
      ...formatData(values),
      userId: identityId,
      email,
      phoneNumber,
    };
    const result = await clientsService.post<ApplicationSubmissionResponse>(
      `/v1/applications/${accountType || 'loan_installment'}/submit`,
      {
        ...body,
      },
    );
    return result;
  };

  const onSuccess = async (
    applicationSubmission: ApplicationSubmissionResponse,
  ) => {
    // If we get an address or identity verification rejection then we have not updated the user and we expect the user to retry.
    // If this is the case we should not log a application.submission.succeeded event and treat this as a metrics/GTM no-op.
    const rejectionType = applicationSubmission.rejection?.code.type;
    const rejectionSubCode = applicationSubmission.rejection?.code?.subCode;
    if (
      rejectionSubCode != undefined &&
      [
        'ADDRESS_VERIFICATION',
        'IDENTITY_VERIFICATION',
        'UNPROCESSABLE_APPLICATION',
      ].includes(rejectionSubCode)
    ) {
      logOnboardingEvent({
        eventName: 'application rejection cannot verify id, address, or dob',
        email,
      });

      return;
    }

    // Unclaimable SSN rejection types should prompt with the DOB/SSN confirmation dialog
    if (rejectionSubCode === 'UNCLAIMABLE_SSN') {
      logOnboardingEvent({
        eventName: 'application rejection unclaimable ssn',
        email,
      });

      return;
    }

    // Refetch the current user and set its query data to the latest value
    // We could invalidate here, but we want to refetch/update cache immediately
    await queryClient.fetchQuery<User>(USE_CURRENT_USER_QUERY_KEY, {
      staleTime: 0,
      cacheTime: 0,
    });

    track({
      namespace: 'application',
      event: 'submission.succeeded',
      attributes: {
        accountType: 'loan_installment',
        loanProductId: INSTALLMENTS_PRODUCT_ID,
        digitalServiceId: 'service_1',
      },
      properties: {
        newIdvFlow: true,
      },
      sendAsConversionEventToMobileApp: true,
    });

    removeCookie('kovo_referral_code');
    removeCookie('kovo_marketing_attributions');

    logOnboardingEvent({
      eventName: 'application submitted',
      email,
    });

    await queryClient.invalidateQueries({
      queryKey: [USE_APPLICATION_STATUS_V2_QUERY_KEY],
    });

    // Adverse action rejections
    if (applicationSubmission.status === 'REJECTED') {
      if (!rejectionType || !rejectionSubCode) {
        throw new KovoError('Invalid application rejection type').addMetadata({
          rejectionType,
          rejectionSubCode,
        });
      }

      if (rejectionType === 'ADVERSE_ACTION') {
        if (['INCOME', 'WATCHLIST'].includes(rejectionSubCode)) {
          logOnboardingEvent({
            eventName: 'application rejection adverse action',
            email,
          });

          return history.push(
            `/adverse-action-notice-v2/${applicationSubmission.createdAt}`,
          );
        }

        if (rejectionSubCode === 'AGE') {
          logOnboardingEvent({
            eventName: 'application rejection adverse action under 18',
            email,
          });

          return history.push(
            `/adverse-action-age/${applicationSubmission.createdAt}`,
          );
        }

        throw new KovoError(
          'Unhandled adverse action rejection code',
        ).addMetadata({
          rejectionType,
          rejectionSubCode,
        });
      }

      if (rejectionType === 'KYC') {
        if (rejectionSubCode === 'DUPLICATE_APPLICATION') {
          logOnboardingEvent({
            eventName: 'application rejection duplicate',
            email,
          });

          if (!isUseSsnServiceFeatureFlagEnabled()) return;

          if (user?.isDuplicateUser && user?.existingUserEmail) {
            return history.push('/account-exists');
          }

          return;
        }

        // Handle unverifiable KYC rejection
        if (rejectionSubCode === 'UNVERIFIABLE') {
          logOnboardingEvent({
            eventName: 'application rejection unverifiable',
            email,
          });
          return history.push(
            `/application-rejected-v2/${applicationSubmission.createdAt}`,
          );
        }

        throw new KovoError('Unhandled KYC rejection code').addMetadata({
          rejectionType,
          rejectionSubCode,
        });
      }

      const exhaustiveRejectionType = rejectionType;
      throw new KovoError(
        `Invalid application rejection type: ${exhaustiveRejectionType}`,
      ).addMetadata({
        rejectionType,
        rejectionSubCode,
      });
    }

    // If the application is not rejected, we can redirect to the pending page
    return history.push('/apply-pending');
  };

  const onError = (error: KovoAddressUpdateError) => {
    window.scrollTo(0, 0);
  };

  return useMutation<
    ApplicationSubmissionResponse,
    KovoAddressUpdateError,
    FormValues
  >(submitApplication, {
    onSuccess,
    onError,
  });
}

export default useApplicationSubmitV2;
